//Type
export const ProductActionTypes = {
  ASYNC_PRODUCT: '@product/ASYNC_PRODUCT',
  ASYNC_SESSION: '@product/ASYNC_SESSION',
  CHANGE_ERROR_STATUS: '@product/CHANGE_ERROR_STATUS',
  CHANGE_PRODUCT: '@product/CHANGE_PRODUCT',
  SET_PRODUCT_CODE: '@product/SET_PRODUCT_CODE',
};

const INITIAL_STATE = {
  product: null,
  error: null,
  code: null,
};

export const productsReducer = (state = INITIAL_STATE, {type, payload}) => {
  switch (type) {
  case ProductActionTypes.CHANGE_PRODUCT:
    return {...state, product: payload};
  case ProductActionTypes.CHANGE_ERROR_STATUS:
    return {...state, error: payload};
  case ProductActionTypes.SET_PRODUCT_CODE:
    return {...state, code: payload};
  default:
    return state;
  }
};

export const setCode = (code) => ({
  type: ProductActionTypes.SET_PRODUCT_CODE,
  payload: code,
});

export const getProducts = (code) => ({
  type: ProductActionTypes.ASYNC_PRODUCT,
  payload: code,
});

export const getSession = (session) => ({
  type: ProductActionTypes.ASYNC_SESSION,
  payload: session,
});

export const changeErrorStatus = (err) => ({
  type: ProductActionTypes.CHANGE_ERROR_STATUS,
  payload: err,
});

export const changeProduct = (product) => ({
  type: ProductActionTypes.CHANGE_PRODUCT,
  payload: product,
});

export default productsReducer;

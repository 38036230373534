import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  table {
    width: calc(100% - 120px);
    margin-top: 30px;

    thead > tr > th {
      padding: 15px 0;
      border-radius: 3.5px;
      height: 40px;
      text-align: center;

      font-size: 18px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.21px;

      color: #414141;
      background-color: #fbfbfb;
    }

    tbody > tr {
      &.amount td {
        color: #d3b400;
        font-weight: bold;
      }
      > td {
        padding: 8px;
        text-align: left;

        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.21px;
        color: #757575;
      }
    }
  }
`;

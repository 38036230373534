import React from 'react';

import IconLoading from './loading-svg';
import {Container} from './styles';

const Loading = () => {
  return (
    <Container>
      <IconLoading />
    </Container>
  );
};

export default Loading;

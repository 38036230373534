import styled, {css} from 'styled-components';

export const Container = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: 83px;
`;

export const TitleInput = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;

  p {
    font-family: 'Geomanist Black';
    color: #c6a106;
  }

  small {
    margin-left: 6px;
    font-size: 10px;
    color: #927602;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 15px;

  position: relative;

  background: #fff;
  border-radius: 6px;
  border: 1px solid #414141;
  color: #c6a007;
  & + div {
    margin-top: 8px;
  }
  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props => props.disabled && css`
    border: solid 1px #f9f7f7;
    background-color: #f9f7f7;
  `}

  input {
    width: 100%;
    height: 100%;
    background: transparent;

    font-size: 14px;

    border: 0;
    color: #414141;

    &::placeholder {
      color: #666360;
    }

    ${props =>
    props.isErrored &&
      css`
        padding-right: 20px;
      `}
  }
  input[type=date] {
    text-transform: uppercase;
    &::-webkit-datetime-edit {
      color: ${props => props.defaultValue === '' ? '#666360' : '#414141'};
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-text-fill-color: #414141 !important;
  }
  svg {
    margin-right: 16px;
  }
`;

export const Error = styled.div`
  height: 20px;
  position: absolute;
  right: 10px;
  svg {
    margin: 0;
  }
`;

export const ErrorCaption = styled.p`
  font-size: 12px;
  text-align: center;

  color: #b80101;
  position: absolute;
  bottom: -20px;
  left: 0;
`;

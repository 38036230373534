//Type
export const CampaignsActionTypes = {
  ASYNC_CAMPAIGNS: '@campaigns/ASYNC_CAMPAIGNS',
  CHANGE_CAMPAIGNS: '@campaigns/CHANGE_CAMPAIGNS',
};

const INITIAL_STATE = {
  campaigns: {},
};

export const campaignsReducer = (state = INITIAL_STATE, {type, payload}) => {
  switch (type) {
  case CampaignsActionTypes.CHANGE_CAMPAIGNS:
    return {...state, campaigns: payload};
  default:
    return state;
  }
};

export const getCampaigns = (payload) => ({
  type: CampaignsActionTypes.ASYNC_CAMPAIGNS,
  payload,
});

export const changeCampaigns = (campaign) => ({
  type: CampaignsActionTypes.CHANGE_CAMPAIGNS,
  payload: campaign,
});

export default campaignsReducer;

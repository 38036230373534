import React from 'react';
import {withTranslation} from 'react-i18next';
import {ToastContainer} from 'react-toastify';
import './i18n';

import Routes from './routes';
import GlobalStyle from './styles/global';
import 'react-toastify/dist/ReactToastify.css';
import 'react-credit-cards/es/styles-compiled.css';

const App = () => {
  return (
    <>
      <Routes />
      <ToastContainer />
      <GlobalStyle />
    </>
  );
};

export default withTranslation()(App);

import {shade} from 'polished';
import styled, {css} from 'styled-components';

export const Container = styled.button`
  width: 100%;
  max-width: 525px;
  padding: 20px 0 15px 0;
  margin: 20px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0;

  font-size: calc(14px + (8 / 1200) * 100 * 1vw);

  background: #c6a007;
  color: #fff;
  transition: background-color 0.2s;

  ${props => props.outlined && css`
      background: #fff;
      color: #c6a007;
      border: 1px solid #c6a007;
    `
}

  &:hover {
    background: ${shade(0.2, '#c6a007')};
    color: #fff;
  }

  ${props => props.disabled && css`
    opacity: 0.34;
    cursor: default;
  `
}
`;

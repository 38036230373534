export const routes = {
  ROOT: '/',
  PRODUCT: '/:code',
  PRODUCT_INFO: '/:code/produto',
  PRODUCT_PRESENTIAL: '/:code/presencial',
  PERSONAL_DATA: '/:code/info',
  PAYMENT: '/:code/pagamento',
  PAYMENT_MULTIPLE: '/:code/presencial/pagamento',
  PAYMENT_CONFIRMED: '/:code/confirmar',
  PAYMENT_CONFIRMED_PIX: '/:code/confirmar/pix',
  PAYMENT_CONFIRMED_BOLETO: '/:code/confirmar/boleto',
  EXTERNAL_PRODUCT: '/produto/:productId',
  EXTERNAL_SESSION: '/sessao/:id',
};

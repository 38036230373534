import {all} from 'redux-saga/effects';

import Campaigns from './campaigns.saga';
import Payments from './payments.saga';
import Products from './products.saga';

export default function* RootSaga() {
  yield all([
    Products(),
    Payments(),
    Campaigns(),
  ]);
}

import {Icon} from 'components';
import React, {useState, useCallback} from 'react';

import {Container, TitleInput, Content, ErrorCaption} from './styles';

function Select({
  Data,
  title,
  message,
  children,
  inputWidth = '100%',
  error,
  disabled = false,
  optional,
  defaultValue,
  onChange = () => { },
  onBlurFormik = () => { },
  ...rest
}) {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = useCallback(() => {
    if (isFocused) {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  }, [isFocused]);

  return (
    <Container style={{width: inputWidth}}>
      {title && (
        <TitleInput>
          <p>{title}</p>
          {optional && <small>(opcional)</small>}
        </TitleInput>
      )}
      <Content style={{borderColor: isFocused && '#c6a007'}} isErrored={!!error} disabled={disabled}>
        {!disabled && <Icon
          name='icon-arrow-down'
          color='#414141'
          width={12}
          height={8}
        />}
        <select
          style={{
            color: defaultValue === '' || defaultValue === undefined ? '#666360'
              : ' #414141',
          }}
          value={defaultValue}
          onChange={onChange}
          onFocus={handleInputFocus}
          onBlur={event => {
            onBlurFormik(event);
            handleInputFocus();
          }}
          disabled={disabled}
          {...rest}
        >
          {message && <option style={{color: '#666360'}} value=''>{message}</option>}
          {Data && Data.map(bank => (
            <option key={bank.value} value={bank.value}>{bank.option}</option>
          ))}
        </select>
        {error && <ErrorCaption>{error}</ErrorCaption>}
        {children}
      </Content>
    </Container>
  );
}

export default Select;

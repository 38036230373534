
import Loading from 'components/Loading';
import React, {Suspense, lazy} from 'react';
import {Route, Switch} from 'react-router-dom';

import ProductProvider from '../hooks/index';
import {routes} from '../utils/routes.constants';

// const Product = lazy(() => import('views/Product'));
const Error = lazy(() => import('views/Error'));
const Initial = lazy(() => import('views/Initial'));
const ProductPresential = lazy(() => import('views/ProductPresential'));
const PersonalData = lazy(() => import('views/PersonalData'));
const Payment = lazy(() => import('views/Payment'));
const PaymentMultiple = lazy(() => import('views/PaymentMultiple'));
const PaymentConfirmation = lazy(() => import('views/PaymentConfirmation'));
const PaymentBoleto = lazy(() => import('views/PaymentBoleto'));

const ExternalProduct = lazy(() => import('views/ExternalProduct'));

function Routes() {
  return (
    <Suspense fallback={<Loading />}>
      <Route path={routes.PRODUCT} component={ProductProvider}>
        <ProductProvider>
          <Switch>
            <Route exact path={routes.PAYMENT_CONFIRMED_PIX} component={PaymentConfirmation} />
            <Route exact path={routes.PRODUCT_INFO} component={Initial} />
            <Route exact path={routes.PRODUCT_PRESENTIAL} component={ProductPresential} />
            <Route exact path={routes.PERSONAL_DATA} component={PersonalData} />
            <Route exact path={routes.PAYMENT} component={Payment} />
            <Route exact path={routes.PAYMENT_MULTIPLE} component={PaymentMultiple} />
            <Route exact path={routes.PAYMENT_CONFIRMED} component={PaymentConfirmation} />

            <Route exact path={routes.PAYMENT_CONFIRMED_BOLETO} component={PaymentBoleto} />
          </Switch>
        </ProductProvider>
      </Route>
      <Route exact path={routes.EXTERNAL_PRODUCT} component={ExternalProduct} />
      <Route exact path={routes.EXTERNAL_SESSION} component={ExternalProduct} />
      <Route exact path={routes.ROOT} component={Error} />
    </Suspense>
  );
}

export default Routes;

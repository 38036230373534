import React, {useState, useEffect} from 'react';

import {Container, Check, Label} from './styles';

const Checkbox = ({children, onChange}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (onChange) {
      onChange(checked);
    }
  }, [onChange, checked]);

  return (
    <Container>
      <Check
        onClick={() => setChecked(!checked)}
        className={checked ? 'checked' : ''}
      />
      <Label>{children}</Label>
    </Container>
  );
};

export default Checkbox;

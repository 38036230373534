
import GeomanistBlack from 'assets/fonts/geomanist/Geomanist-Black.otf';
import GeomanistBold from 'assets/fonts/geomanist/Geomanist-Bold.otf';
import GeomanistMedium from 'assets/fonts/geomanist/Geomanist-Medium.otf';
import GeomanistUltra from 'assets/fonts/geomanist/Geomanist-Ultra.otf';
import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
  @font-face {
    font-family: "Geomanist Medium";
    src: url(${GeomanistMedium});
  }
  @font-face {
    font-family: "Geomanist Bold";
    src: url(${GeomanistBold});
  }
  @font-face {
    font-family: "Geomanist Black";
    src: url(${GeomanistBlack});
  }
  @font-face {
    font-family: "Geomanist Ultra";
    src: url(${GeomanistUltra});
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  body {
    background: #fff;
    color: #414141;
    -webkit-font-smoothing: antialiased;

    div.Toastify__toast-body {
      font-size: 14px;
      font-weight: 900;
    }

    input[type='email'] {
      text-transform: lowercase;
      ::placeholder {
        text-transform: none;
   }
    }
  }
  body, input, button {
    font-family: 'Geomanist Medium', sans-serif;
    font-size: 16px;
  }
  button {
    cursor: pointer;
  }
`;

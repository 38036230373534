import validator from 'is-my-date-valid';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

export const formatDateWithBRTimezone = (date) => {
  if (!date) {
    return '';
  }
  return momentTimezone.tz(date, 'America/Fortaleza').format();
};

export const formatDate = date => {
  if (!date) {
    return '';
  }
  return date.split('/').reverse().join('-');
};

export const validationDueDate = (date) => {
  const validate = validator({format: 'MM/YY'});

  const newDate = new Date(),
    yearDate = newDate.getFullYear(),
    monthDate = new Date(),
    month = monthDate.getMonth() + 1,
    newyearDate = String(yearDate).substr(2);
  let result = true;

  if (date) {
    const validDate = validate(date);

    const inputDate = date.split('/'),
      inputMonth = Number(inputDate[0]),
      inputYear = Number(inputDate[1]);

    if (!validDate) {
      result = false;
    }

    if (inputYear < Number(newyearDate)) {
      result = false;
    }

    if (inputYear === Number(newyearDate) && inputMonth < month) {
      result = false;
    }
  }

  return result;
};

export const formatMoney = (int) => {
  let tmp = int + '',
    neg = false;

  if (tmp.indexOf('-') === 0) {
    neg = true;
    tmp = tmp.replace('-', '');
  }

  if (tmp.length === 1) {
    tmp = '0' + tmp;
  }

  tmp = tmp.replace(/([0-9]{2})$/g, ',$1');
  if (tmp.length > 6) {
    tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
  }

  if (tmp.length > 9) {
    tmp = tmp.replace(/([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, '.$1.$2,$3');
  }

  if (tmp.length > 12) {
    tmp = tmp.replace(/([0-9]{3}).([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, '.$1.$2.$3,$4');
  }

  if (tmp.indexOf('.') === 0) {
    tmp = tmp.replace('.', '');
  }
  if (tmp.indexOf(',') === 0) {
    tmp = tmp.replace(',', '0,');
  }

  return (neg ? (''.concat('-', tmp)) : (''.concat(tmp)));
};

export const formatPrice = value => {
  const regPrice = /(\d{1,}x)|[R$.\s]/g;
  const valueFormated = String(value).replace(regPrice, '').replace('.', '').replace(',', '.');
  return typeof value === 'number' ? value.toFixed(2) : valueFormated;
};

export const converterCreditCardNames = (cardName) => {
  switch (cardName) {
  case 'visa':
    return 'Visa';
  case 'mastercard':
    return 'Master';
  case 'hipercard':
    return 'Hipercard';
  case 'amex':
    return 'Amex';
  case 'elo':
    return 'Elo';
  case 'dinersclub':
    return 'Diners';
  case 'discover':
    return 'Discover';
  default:
    return cardName;
  }
};

export const isValidCard = (cardName) => {
  switch (cardName) {
  case 'Visa':
  case 'Master':
  case 'Hipercard':
  case 'Amex':
  case 'Elo':
  case 'Diners':
  case 'Discover':
    return true;
  default:
    return false;
  }
};

export const translateInputsError = (value) => {
  const newValue = value.toLowerCase();
  switch (newValue) {
  case 'firstname':
    return 'nome';
  case 'lastname':
    return 'sobrenome';
  case 'email':
    return 'e-mail';
  case 'phone':
    return 'telefone';
  case 'street':
    return 'endereço';
  case 'number':
    return 'número';
  case 'complement':
    return 'complemento';
  case 'district':
    return 'bairro';
  case 'city':
    return 'cidade';
  case 'state':
    return 'estado';
  case 'country':
    return 'país';
  case 'postalcode':
    return 'CEP';
  default:
    return newValue;
  }
};

export function getCookie(cname) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const noSpace = event => {
  event.which === 32 && event.preventDefault();
};

export const currencyFormatter = value => {
  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format((value || 0) / 100);

  return `${amount}`;
};

export const isValidBirthdate = value => {
  const AGES = {
    MIN: 9,
    MAX: 150,
  };

  var birthDate = moment(formatDate(value)).format('YYYY-MM-DD');
  var age = moment().diff(birthDate, 'years', false);

  if (age < AGES.MIN || age > AGES.MAX) {
    return false;
  }
  return true;
};

export const getQueryVariableValue = (variable, customQuery) => {
  const query = customQuery || window.location.search.substring(1);
  const variablesOnTheQuey = query.split('&');

  for (var i = 0; i < variablesOnTheQuey.length; i++) {
    var pair = variablesOnTheQuey[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }

  return false;
};

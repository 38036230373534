import {call, put, all, takeEvery} from 'redux-saga/effects';
import {CampaignsActionTypes, changeCampaigns} from 'store/reducers/campaigns.reducer';
import {history} from 'store/store.config';
import {routes} from 'utils/routes.constants';
import {getCampaigns} from 'utils/webApi';

function* campaignsLists({payload}) {
  try {
    const response = yield call(getCampaigns, payload);
    yield put(changeCampaigns(response));
  } catch (error) {
    history.push(routes.ROOT);
  }
}

export default function* MySaga() {
  yield all([
    yield takeEvery(CampaignsActionTypes.ASYNC_CAMPAIGNS, campaignsLists),
  ]);
}

import React, {useRef, useCallback, useState} from 'react';
import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask';

import {currencyFormatter} from '../../utils/functions';
import Icons from '../Icons';
import {Container, Content, TitleInput, Error, ErrorCaption} from './styles';

const InputInternal = ({inputRef, mask, onChange, currency, ...props}) => {
  if (currency) {
    return <NumberFormat {...props} type='tel' inputMode='numeric' onChange={onChange} format={currencyFormatter} ref={inputRef} />;
  }
  if (mask) {
    return <MaskedInput {...props} onChange={onChange} mask={mask} guide={false} ref={inputRef} />;
  }
  return <input {...props} onChange={onChange} ref={inputRef} />;
};

function Input({
  name,
  title,
  error,
  defaultValue,
  onChange = () => { },
  icon: Icon,
  inputWidth = '100%',
  optional = false,
  onBlurFormik = () => { },
  onFocus = () => { },
  onKeyPress = () => { },
  noPaste = false,
  currency = false,
  disabled = false,
  mask,
  ...rest
}) {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(!isFocused);
  }, [isFocused]);

  const onPaste = event => {
    noPaste && event.preventDefault();
  };

  return (
    <Container style={{width: inputWidth, position: 'relative'}}>
      {title && (
        <TitleInput>
          <p>{title}</p>
          {optional && <small>(opcional)</small>}
        </TitleInput>
      )}
      <Content style={{borderColor: isFocused && '#c6a007'}} isErrored={!!error} defaultValue={defaultValue} disabled={disabled}>
        {Icon && <Icons name={Icon} width={20} heigth={20} />}
        <InputInternal
          onFocus={(event) => {
            onFocus(event);
            handleInputFocus();
          }}
          onBlur={event => {
            onBlurFormik(event);
            handleInputFocus();
          }}
          value={defaultValue}
          onChange={onChange}
          onPaste={onPaste}
          onKeyPress={onKeyPress}
          currency={currency}
          mask={mask}
          inputRef={inputRef}
          disabled={disabled}
          {...rest}
        />
        {error && (
          <Error title={error}>
            <Icons name='icon-warning' width={20} heigth={20} />
          </Error>
        )}
        {error && <ErrorCaption>{error}</ErrorCaption>}
      </Content>
    </Container>
  );
}

export default Input;

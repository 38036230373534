import {Icon} from 'components';
import React, {useState, useCallback, useEffect, useMemo, createContext, useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useRouteMatch, useHistory, useLocation} from 'react-router-dom';
import {postInterest, changeCampaignHasExpired, resetForm, changeInvalidInterest} from 'store/reducers/payments.reducer';
import {getProducts} from 'store/reducers/products.reducer';
import {getQueryVariableValue} from 'utils/functions';

import CountdownTimer from '../views/Product/components/Countdown';
import Modal from '../views/Product/components/Modal/index';
import {Container, Content, Viewer, Logo, Header, HeaderLogoMobile} from '../views/Product/styles/index';
import useWidth from './useWidth';

export const ProductContext = createContext();
export const PAGES = {
  PRODUCT_INFO: {
    screen: 0,
    path: '/:code/produto',
  },
  PRODUCT_PRESENTIAL: {
    screen: 0,
    path: '/:code/presencial',
  },
  PERSONAL_DATA: {
    screen: 1,
    path: '/:code/info',
  },
  PAYMENT: {
    screen: 2,
    path: '/:code/pagamento',
  },
  PAYMENT_MULTIPLE: {
    screen: 2,
    path: '/:code/presencial/pagamento',
  },
  PAYMENT_CONFIRMED: {
    screen: 3,
    path: '/:code/confirmar',
  },
  PAYMENT_CONFIRMED_PIX: {
    screen: 3,
    path: '/:code/confirmar/pix',
  },
  PAYMENT_CONFIRMED_BOLETO: {
    screen: 3,
    path: '/:code/confirmar/boleto',
  },
};

const ProductProvider = ({children}) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const width = useWidth();
  const {code} = match.params;

  const location = useLocation();
  const nextScreen = location?.state?.screen;
  const shouldNotRedirect = location?.state?.shouldNotRedirect;

  const statusPayment = useSelector((state) => state.Payments.statusPayment);
  const expiredProduct = useSelector(({Payments}) => Payments.campaign_has_expired);
  const invalidInterest = useSelector(({Payments}) => Payments.invalid_interest);
  const campaignsLists = useSelector(({Campaigns}) => Campaigns.campaigns);

  const [screen, setScreen] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [isMultiple, setIsMultiple] = useState(false);
  const [presentialOptions, setPresentialOptions] = useState({});
  const [custumerPayment, setCustumerPayment] = useState({});
  const [counter, setCounter] = useState(0);
  const [stopCounter, setStopCounter] = useState(false);
  const [finalTimestamp, setFinalTimestamp] = useState(null);
  const [infosProduct, setInfosProduct] = useState(undefined);
  const [pixelId, setPixelId] = useState(null);

  useEffect(() => {
    if (location.pathname === `/${code}`) {
      if (getQueryVariableValue('skip')) {
        history.push(`${PAGES.PRODUCT_INFO.path.replace(':code', code)}?skip=${getQueryVariableValue('skip')}`);
        return;
      }
      history.push(PAGES.PRODUCT_INFO.path.replace(':code', code));
    }
    if (infosProduct !== undefined && infosProduct?.session === undefined) {
      return () => {
        if (history.action === 'POP') {
          setInfosProduct({...infosProduct, session: undefined});
        }
      };
    }
  });

  useEffect(() => {
    if (['produto', 'sessao'].includes(code)) {
      return;
    }

    dispatch(getProducts({
      code, callback: (infosProduct = undefined) => {
        setInfosProduct(infosProduct);
        setIsMultiple(!!infosProduct?.product?.options?.length > 0);
        setPixelId(infosProduct?.pixelId);

        if (infosProduct?.product?.['redirect_uri']) {
          const {product: {redirect_uri: url}, session} = infosProduct;

          if (url.includes('<TOKEN>')) {
            window.location.href = url.replace('<TOKEN>', session);
          } else if (!shouldNotRedirect) {
            document.cookie = `${infosProduct.product.id}=${infosProduct.session}; path=/;`;
            window.location.href = url;
          }
        }

        if (getQueryVariableValue('skip') == 1) {
          if (!!infosProduct?.product?.options?.length > 0) {
            history.push(PAGES.PRODUCT_PRESENTIAL.path.replace(':code', code), {});

          } else {
            history.push(PAGES.PERSONAL_DATA.path.replace(':code', code), {});
          }
          return;
        }

        if (!nextScreen) {
          history.push(PAGES.PRODUCT_INFO.path.replace(':code', code));
        } else {
          setScreen(nextScreen);
          history.push(PAGES.PERSONAL_DATA.path.replace(':code', code), {});
        }
      },
    }));
    // eslint-disable-next-line
    }, [code, dispatch, history]);

  useEffect(() => {
    if (screen === 1 && !isMultiple) {
      dispatch(postInterest(Object.assign({session: infosProduct.session})));
      setStopCounter(false);
    }
  }, [screen, infosProduct, dispatch]);

  useEffect(() => {
    setStopCounter(false);
    if (infosProduct !== undefined && infosProduct?.session === undefined) {
      setStopCounter(true);
      handlerScreen(PAGES.PRODUCT_INFO.path);
      dispatch(resetForm());
      dispatch(changeCampaignHasExpired(false));
      dispatch(changeInvalidInterest(false));

      dispatch(getProducts({
        code, callback: (infosProduct = undefined) => {
          setInfosProduct(infosProduct);
        },
      }));
    }
    if (infosProduct) {
      setCounter(infosProduct.timeout);
    }
  }, [infosProduct]);

  const shouldBeStartCounter = useMemo(() => {
    if ((counter > 0) && !stopCounter) {
      return true;
    } else {
      return false;
    }
  }, [counter, stopCounter, screen]);

  useEffect(() => {
    if (shouldBeStartCounter && finalTimestamp) {
      const remainingTime =
          counter > 0 && setInterval(() => setCounter(finalTimestamp - Math.floor(Date.now() / 1000)), 1000);
      return () => clearInterval(remainingTime);
    }
    if (!shouldBeStartCounter && screen === 0 && finalTimestamp && window.location.href.includes('produto')) {
      history.go(0);
    }
  }, [shouldBeStartCounter, counter, finalTimestamp]);

  useEffect(() => {
    if (infosProduct) {
      if (infosProduct.timeout === counter && shouldBeStartCounter) {
        setFinalTimestamp(Math.ceil(Date.now() / 1000) + parseInt(infosProduct.timeout, 10));
      }
    }
  }, [infosProduct, counter, shouldBeStartCounter]);

  useEffect(() => {
    if (statusPayment && statusPayment === 200) {
      setStopCounter(true);
    }
  }, [statusPayment]);

  const handlerScreen = (path) => {
    const currentPath = Object.values(PAGES).find((e) => e.path === path);
    if (currentPath) {
      setScreen(currentPath.screen);
      if (currentPath.screen === 3) {
        history.replace(path.replace(':code', code));
      } else {
        history.push(path.replace(':code', code));
      }
    }
  };

  const handleTryToBuyAgain = useCallback(() => {
    setOpenModal(false);
    setStopCounter(true);
    handlerScreen(PAGES.PRODUCT_INFO.path);

    dispatch(resetForm());

    dispatch(getProducts({code, session: infosProduct.session}));
    setCounter(infosProduct.timeout);
    // eslint-disable-next-line
    }, [infosProduct, code, dispatch]);

  const handleOpenModal = useCallback((value) => {
    if (counter <= 0 && value && !stopCounter) {
      setOpenModal(true);
    }
  }, [counter, stopCounter]);

  const handleBuyAgain = useCallback(() => {
    setStopCounter(true);
    handlerScreen(PAGES.PRODUCT_INFO.path);
    dispatch(resetForm());

    dispatch(getProducts({code, session: infosProduct.session}));
    dispatch(changeCampaignHasExpired(false));
    dispatch(changeInvalidInterest(false));
    // eslint-disable-next-line
    }, [infosProduct, code, dispatch]);

  return (
    <ProductContext.Provider value={{
      infosProduct,
      infos: infosProduct ? infosProduct.product : undefined,
      progress: screen,
      handlerScreen,
      counterChildPersonal: <CountdownTimer counter={counter} onChange={(value) => {
        if (value) {
          handleOpenModal(value);
        }
      }} />,
      counterChildPayment: <CountdownTimer counter={counter} onChange={(value) => {
        if (value) {
          handleOpenModal(value);
        }
      }} />,
      counterChildConfirmed: (statusPayment && statusPayment !== 200) && <CountdownTimer counter={counter} onChange={(value) => {
        if (value) {
          handleOpenModal(value);
        }
      }} />,
      setCustumerPayment,
      custumerPayment,
      isMultiple,
      pixelId,
      presentialOptions,
      setPresentialOptions,
      setInfosProduct,
      setStopCounter,
      numberOfParticipants: campaignsLists?.quantity || 1,
    }}>
      <Container>
        {infosProduct &&
        <>
          {width <= 1024 && (
          <HeaderLogoMobile>
            <Icon name='icon-febracis-logo' width='100px' height='40px' />
          </HeaderLogoMobile>
          )}
          <Header>
            {width <= 550 && <img src={infosProduct.product.banners.phone} alt='banner' />}
            {width > 550 && width < 1024 && <img src={infosProduct.product.banners.tablet} alt='banner' />}
            {width >= 1024 && <img src={infosProduct.product.banners.desktop} alt='banner' />}
            {width > 1024 && (
            <Logo>
              <Icon name='icon-febracis-logo' />
            </Logo>
            )}
          </Header>

          <Content>
            <Viewer isVisible={true}>
              {children}
            </Viewer>
          </Content>
        </>
        }
      </Container>
      {openModal &&
      <Modal onClick={handleTryToBuyAgain} subtitle='O seu tempo de compra acabou! Por favor tente novamente.' />
      }
      {expiredProduct &&
      <Modal onClick={handleBuyAgain} subtitle='A oferta do produto expirou! Por favor tente novamente.' />
      }
      {invalidInterest &&
      <Modal onClick={handleBuyAgain} subtitle='Falha na oferta. Entre em contato com o suporte.' />
      }
    </ProductContext.Provider>
  );
};

export const useProduct = () => {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error('useProduct must be used within a ProductProvider');
  }

  return context;
};

export default ProductProvider;

import Axios from 'axios';

import {urlApi} from './urls.constants';

export const api = Axios.create({
  baseURL: urlApi,
  headers: {
    'Content-Type': 'application/json',
  },
});

const baseHeaders = (othersHeaders = {}) => ({
  headers: {
    'Content-Type': 'application/json',
    ...othersHeaders,
  },
});

export const Get = (url, params, headers) => {
  return Axios.get(url, {params, ...baseHeaders(headers)})
    .then(data => data.data)
    .catch(error => {
      throw error;
    });
};

export const GetPromise = async (url, params, headers) => {
  const response = await Axios.get(url, {params, ...baseHeaders(headers)});
  return response.data;
};

export const PostPromise = async (url, data, headers) => {
  const response = await Axios.post(url, data, baseHeaders(headers));
  return response.data;
};

export const Post = (url, data, headers) => {
  return Axios.post(url, data, baseHeaders(headers))
    .then(data => data.data)
    .catch(error => {
      throw error;
    });
};

export const Put = (url, data, headers) => {
  return Axios.put(url, data, baseHeaders(headers))
    .then(data => data.data)
    .catch(error => {
      throw error;
    });
};

export const Delete = (url, data, headers) => {
  return Axios.delete(url, {data, headers: baseHeaders(headers).headers})
    .then(data => data.data)
    .catch(error => {
      throw error;
    });
};

export const Patch = (url, data, headers) => {
  return Axios.patch(url, data, baseHeaders(headers))
    .then(data => data.data)
    .catch(error => {
      throw error;
    });
};

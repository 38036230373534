export default {
  'ADDRESS': 'Endereço',
  'AND': 'e',

  'BOLETO': 'Boleto',
  'BOLETO_LINK': 'Link do Boleto',

  'CARD': 'Cartão',
  'CARD_NUMBER': 'Número do Cartão',
  'CARD_NUMBER_13_OR_MORE_CHAR': 'O número do cartão deve ter 13 ou mais caracteres',
  'CARD_NUMBER_18_OR_LESS_CHAR': 'O número do cartão deve ter 18 ou menos caracteres',
  'CARD_STATUS': 'Status do Cartão',
  'CHECK': 'CONFIRA',
  'CHECKOUT': 'Finalizar Compra',
  'CHECKOUT_BACK': 'Voltar para Pagamento',
  'COMPLEMENT': 'Complemento',
  'CONFIRMATION': 'Confirmação',
  'CITY': 'Cidade',
  'CPF': 'CPF',
  'CPF_AT_LEAST_11_CHAR': 'CPF deve ter pelo menos 11 caracteres',
  'CVV': 'CVV',
  'CCV_GREATER_OR_EQUAL_3': 'CVV deve ser maior ou igual a 3',
  'CCV_LESS_OR_EQUAL_4': 'CVV deve ser menor ou igual a 4',
  'CODE_COPIED': 'Código Copiado com Sucesso!',
  'PAYMENT_CONFIRME': 'Confirmar Pagamento',
  'COPY_CODE_DESCRIPTION': 'OU COPIE O CÓDIGO ABAIXO E UTILIZE O PIX COPIA E COLA NO APLICATIVO QUE VOCÊ VAI REALIZAR O PAGAMENTO',
  'CREDIT_CARD': 'Crédito',
  'COPYPRODUCTLINK': 'COPIAR LINK DO PIX',

  'DATA': 'Dados',
  'DISTRICT': 'Bairro',
  'DUE_DATE': 'Vencimento',

  'EMAIL': 'E-mail',
  'EMAIL_CONFIRMATION': 'Confirmação do E-mail',
  'EMAIL_NO_MATCH': 'O e-mail não confere',
  'ERROR_MSG_TWO_CARDS': 'CORRIJA OS DADOS DESTACADOS ABAIXO OU PREENCHA COM AS INFORMAÇÕES DE OUTRO CARTÃO',
  'EQUALS_CARDS': 'Cartões devem ser diferentes',
  'EXTRA': 'Seleção Extra',

  'FIRST_NAME': 'Nome',
  'BIRTHDAY': 'Data de Nascimento',
  'GENDER': 'SEXO',
  'MALE': 'Masculino',
  'FEMALE': 'Feminino',

  'GENERATE_BOLETO': 'Gerar Boleto',
  'QR_CODE_FOOTER1': 'VOCÊ TEM ATÉ',
  'QR_CODE_FOOTER2': ' 10 MINUTOS ',
  'QR_CODE_FOOTER3': 'PARA REALIZAR ESSE PAGAMENTO. APÓS ESSE TEMPO SERÁ NECESSÁRIO GERAR UM NOVO CÓDIGO DE PAGAMENTO.',
  'QR_CODE_LOADING_CODE': 'Gerando código para pagamento pix',

  'HOLDER_NAME': 'Nome Impresso no Cartão',

  'INFORMATION': 'Informaçōes',
  'INSTALLMENT': 'Parcela',
  'INVALID_EMAIL': 'E-mail inválido',
  'INVALID_DOCUMENT': 'CPF inválido',
  'INVALID_DOCUMENT_CNPJ': 'CNPJ inválido',
  'INVALID_POSTALCODE': 'CEP inválido',
  'INVALID_DATE': 'Data inválida',
  'INVALID_CARD': 'Cartão não permitido',

  'LAST_NAME': 'Sobrenome',
  'LESS_THAN_40_CHAR': 'Deve ter menos que 40 caracteres',
  'LESS_THAN_80_CHAR': 'Deve ter menos que 80 caracteres',
  'CNPJ_AT_LEAST_14_CHAR': 'CNPJ deve ter pelo menos 14 caracteres',

  'CORPORATE_NAME': 'Razão Social',
  'DOING_BUSINESS': 'Nome Fantasia',
  'MESSAGE_ERROR_CPF': 'Este cpf não é válido para este produto.',
  'MESSAGE_ERROR_CNPJ': 'Este cnpj não é válido para este produto.',

  'MAX_CHAR': 'Deve ter no máximo {{val}} caracteres',
  'MIN_VALUE': 'Valor mínimo {{val}}',

  'NAME_MAX_25_CHAR': 'Nome deve ter no máximo 25 caracteres',
  'NEXT_STEP': 'Próximo Passo',
  'NOT_NUMBERS_AND_SPECIAL_CHARS': 'Não utilizar números ou caracteres especiais',
  'NUMBER': 'Número',
  'PRODUCT_ID': 'Código do pedido',

  'OF': 'de',
  'OF_BOLETO': 'do Boleto',
  'OPEN_QRCODE_SCANNER': 'ABRA O LEITOR DE QRCODE DO SEU APLICATIVO DE PAGAMENTO.',
  'OR': 'ou',

  'PAYMENT': 'Pagamento',
  'PAYMENT_FAILED': 'SEU PAGAMENTO NÃO FOI PROCESSADO',
  'PAYMENT_FAILED_500_DESCRIPTION': 'Não conseguimos processar o seu pagamento. Por favor, tente novamente.',
  'PAYMENT_FAILED_500_DESCRIPTION_BOLETO': 'Não conseguimos gerar seu Boleto. Por favor, tente novamente.',
  'PAYMENT_FAILED_DESCRIPTION': 'Não conseguimos processar o seu pagamento. Verifique os dados e tente novamente.',
  'PAYMENT_FAILED_CARD': 'CARTÃO NÃO AUTORIZADO',
  'PAYMENT_FAILED_CARD_DESCRIPTION': 'Seu cartão não foi autorizado! Entre em contato com a operadora do seu cartão.',
  'PAYMENT_FAILED_BOLETO_DESCRIPTION': 'Não conseguimos gerar seu Boleto! Verifique seus dados e tente novamente.',
  'PAYMENT_SUCCESS': 'COMPRA REALIZADA COM SUCESSO',
  'PAYMENT_SUCCESS_DESCRIPTION': 'Sua compra foi realizada com sucesso! Em breve você receberá um e-mail com mais informações.',
  'PAYMENT_SUCCESS_BOLETO': 'BOLETO GERADO COM SUCESSO',
  'PAY_WITH_TWO_CARDS': 'QUERO PAGAR COM 2 CARTÕES',
  'PERSONAL': 'Pessoais',
  'PERSONAL_DATA': 'Dados Pessoais',
  'PHONE': 'Telefone',
  'PHONE_AT_LEAST_10_CHAR': 'O telefone deve ter pelo menos 10 caracteres',
  'PHONE_MAX_15_CHAR': 'O telefone deve ter no máximo 15 caracteres',
  'POSTAL_CODE': 'CEP',
  'PRODUCT': 'Produto',
  'PRODUCT_INACTIVATED': 'Desculpa, o produto que você procura não está mais a venda.',
  'PRODUCT_NOT_FOUND': 'Desculpa, não conseguimos encontrar o que você buscava.',
  'EXPIRED_TIMER': 'Atenção! O tempo de compra expirou.',
  'PURCHASE_PRICE': 'Valor da Compra',
  'PURCHASE_DATE': 'Data da Compra',

  'REQUIRED_FIELD': 'Campo obrigatório',

  'STATE': 'Estado',
  'SELECT_PAYMENT_METHOD': 'SELECIONE A SUA FORMA DE PAGAMENTO',
  'YES': 'Sim',
  'NO': 'Não',

  'TOTAL': 'total',
  'TOTAL_COST': 'Valor total',
  'TRY_AGAIN': 'Tentar novamente',
  'TRY_AGAIN_WITH_CC': 'Tentar novamente com cartão',
  'TRY_WITH_BOLETO': 'Pagar com boleto',
  'TRY_AGAIN_TITLE': 'Falha na Requisição',
  'TRY_AGAIN_DESC': 'Ocorreu um erro durante a requisição, por favor, tente novamente!',
  'TRY_AGAIN_ACTION': 'Tentar Novamente',
  'TIME_TO_EXPIRES': '{{time}} MINUTOS',
  'TIME_TO_EXPIRES_DESC1': 'VOCÊ TEM ATÉ',
  'TIME_TO_EXPIRES_DESC2': 'PARA REALIZAR ESSE PAGAMENTO. APÓS ESSE TEMPO, O PAGAMENTO SERÁ CANCELADO.',
  'NEW_QRCODE': 'Deseja gerar um novo QRCODE ?',
  'QRCODE_EXPIRED': 'QRCODE Expirou',

  'LOGRADOURO': 'Logradouro',

  'UNPROCESSED_PURCHASE': 'COMPRA NÃO PROCESSADA',

  'VALUE_NOT_ALLOWED': 'Valor nāo permitido',
  'VALIDATE_CPF': 'Validar CPF',
  'VALIDATE_CPF_TXT': 'Digite seu CPF abaixo para validarmos os seus dados e dar continuidade ao seu processo de compra.',
};

import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  width: 100%;
  height: 100%;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.7);

  section {
    min-width: 200px;

    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(174, 174, 174, 0.5);

    header {
      position: relative;
      width: 100%;
      padding: 20px;

      display : flex;
      justify-content: center;
      align-items: center;

      border-radius: 12px;
      background-color: #c6a007;

      span {
        font-size: 22px;
        font-weight: 900;
        text-align: center;
        color: #ffffff;
      }

      button {
        position: absolute;
        right: 20px;

        border: none;
        outline: none;
        background-color: #c6a007;
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 20px;

  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  span {
    margin-top: 20px;

    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: #414141;
  }

  img {
    height: 190px;
  }

  button {
    width: 50%;
  }
`;

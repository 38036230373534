import {connectRouter} from 'connected-react-router';
import {combineReducers} from 'redux';

import Campaigns from './campaigns.reducer';
import Payments from './payments.reducer';
import Products from './products.reducer';

const RootReducer = (history) => combineReducers({
  router: connectRouter(history),
  Products,
  Payments,
  Campaigns,
});

export default RootReducer;

import React, {useCallback, useState} from 'react';
import {usePaymentInputs} from 'react-payment-inputs';
import images from 'react-payment-inputs/images';

import Icons from '../Icons';
import {Container, Content, TitleInput, Error, ErrorCaption} from './styles';

function InputPayment({
  name,
  title,
  error,
  defaultValue,
  onChange = () => { },
  icon: Icon,
  inputWidth = '100%',
  optional = false,
  onBlurFormik = () => { },
  onFocus = () => { },
  ...rest
}) {
  const [isFocused, setIsFocused] = useState(false);

  const {
    meta,
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
  } = usePaymentInputs();

  const handleInputFocus = useCallback(() => {
    if (isFocused) {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  }, [isFocused]);

  return (
    <Container style={{width: inputWidth}}>
      {title && (
        <TitleInput>
          <p>{title}</p>
          {optional && <small>(opcional)</small>}
        </TitleInput>
      )}
      <Content
        {...wrapperProps}
        style={{width: '100%', borderColor: isFocused && '#c6a007'}}
        isErrored={!!error}
      >
        <svg {...getCardImageProps({images})} />
        <input
          {...getCardNumberProps({
            onChange: onChange,
          })}
          onFocus={(event) => {
            onFocus(event);
            handleInputFocus();
          }}
          onBlur={event => {
            onBlurFormik(event, meta.cardType?.type);
            handleInputFocus();
          }}
          defaultValue={defaultValue}
          name={name}
          {...rest}
        />
        {error && (
          <Error title={error}>
            <Icons name='icon-warning' width={20} heigth={20} />
          </Error>
        )}
      </Content>
      {error && <ErrorCaption>{error}</ErrorCaption>}
    </Container>
  );
}

export default InputPayment;

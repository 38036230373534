import {call, put, all, takeEvery} from 'redux-saga/effects';
import {ProductActionTypes, changeProduct, changeErrorStatus, setCode} from 'store/reducers/products.reducer';
import {history} from 'store/store.config';
import {routes} from 'utils/routes.constants';
import {getProduct, getSession} from 'utils/webApi';

function* productValidation({payload}) {
  const {callback, code} = payload;
  try {
    yield put (setCode(code));
    const response = yield call(getProduct, {code});
    if (response.santaclaraUrl) {
      window.location.href = response.santaclaraUrl;
    }
    yield put(changeProduct(response));
    callback(response);
  } catch (error) {
    yield put(changeErrorStatus({status: error.response.status, code_product: payload.code}));
    history.push(routes.ROOT);
  }
}

function* getProductBySession({payload}) {
  try {
    const {callback, session} = payload;
    const response = yield call(getSession, {session});
    yield put(changeProduct(response));
    callback(response);
  } catch (error) {
    yield put(changeErrorStatus({status: error.response.status, code_product: payload.code}));
    history.push(routes.ROOT);
  }
}

export default function* MySaga() {
  yield all([
    yield takeEvery(ProductActionTypes.ASYNC_PRODUCT, productValidation),
    yield takeEvery(ProductActionTypes.ASYNC_SESSION, getProductBySession),
  ]);
}

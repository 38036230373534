import styled, {css} from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column wrap;

  @media (min-width: 1025px) {
    flex-flow: row nowrap;
  }
`;

export const HeaderLogoMobile = styled.div`
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #5d1511;
`;

export const Header = styled.header`
  width: 100%;
  height: 180px;
  min-height: 180px;
  img {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  position: relative;
  display: flex;
  flex-flow: column wrap;

  @media (min-width: 1025px) {
    height: auto;

    img {
      object-position: initial;
    }
  }
`;

export const Logo = styled.div`
  width: 100%;
  margin-top: 20px;
  top: 0;
  left: 0;
  z-index: 10;

  position: absolute;
  display: flex;
  justify-content: center;

  svg {
    width: 112px;
    height: 28px;

    @media(min-width: 768px){
      width: 136px;
      height: 34px;
    }
  }

  @media(min-width: 1024px){
    bottom: 0;
    margin-bottom: 20px;
    align-items: flex-end;

    svg {
      width: 192px;
      height: 48px;
    }
  }

`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  overflow: hidden;

  &::before {
    content: '';
    width: 100%;
    height: 5px;
    background-image: linear-gradient(to right, #a62821 52%, #c6a007 54%);
  }

  @media (min-width: 1025px) {
    min-width: 60%;
  }
`;

export const Viewer = styled.div`
 width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 1;
  visibility: visible;

  transition: opacity 0.5s ease-out;

  ${props =>
    !props.isVisible &&
    css`
      max-height: 0;
      opacity: 0;
      visibility: hidden;
    `}
`;

import {Icon} from 'components';
import React from 'react';

import {Container, Content} from './styles';

function Modal({title, children, onClose}) {
  return (
    <Container>
      <section>
        <header>
          <span>{title}</span>
          <button onClick={onClose}>
            <Icon name='icon-close' width='18px' height='18px' />
          </button>
        </header>
        <Content>
          <p dangerouslySetInnerHTML={{__html: children}}></p>
        </Content>
      </section>
    </Container>
  );
}

export default Modal;

import styled, {css} from 'styled-components';

export const Container = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: 83px;
`;

export const TitleInput = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;

  p {
    font-family: 'Geomanist Black';
    letter-spacing: 0.14px;
    color: #c6a106;
  }

  small {
    margin-left: 6px;
    font-size: 10px;
    letter-spacing: 0.11px;
    color: #927602;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 25px 0 10px;

  background: #fff;
  border-radius: 6px;
  border: 1px solid #414141;
  color: #c6a007;
  & + div {
    margin-top: 8px;
  }
  ${props =>
    props.isErrored && css`
      border-color: #c53030;
    `
}

  ${props => props.disabled && css`
    border: solid 1px #f9f7f7;
    background-color: #f9f7f7;
  `}

  svg {
    position: absolute;
    top: 13px;
    right: 10px;
    z-index: 9;
  }

  select {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;

    font-family: 'Geomanist Bold';
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.18px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    cursor: pointer;

    option {
      color: #414141;
    }

    &:disabled {
      cursor: initial;
    }
  }

  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-text-fill-color: #414141 !important;
  }
`;

export const ErrorCaption = styled.p`
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  text-align: center;
  color: #b80101;
  position: absolute;
  bottom: -20px;
  left: 0;
  white-space: nowrap;
`;

import cuid from 'cuid';

import {Get, Post} from './apiBase';
import {urls} from './urls.constants';

export const getProduct = ({code, session}) => {
  return Get(urls.PRODUCT.replace(':code', code), {session: session, _: cuid()});
};

export const getSession = ({session}) => {
  return Get(urls.SESSION.replace(':id', session));
};

export const postLeads = (params) => {
  return Post(`${urls.LEADS}`, params);
};

export const postPaymentsCards = (params) => {
  return Post(`${urls.PAYMENTS_CARDS}`, params);
};

export const postPayments = (params) => {
  return Post(`${urls.PAYMENTS}`, params);
};

export const postPaymentBoleto = (params) => {
  return Post(`${urls.PAYMENT_BOLETO}`, params);
};

export const getStates = ({country}) => {
  return Get(urls.STATES.replace(':country', country));
};

export const getPostalCode = ({country, postal_code: cep}) => {
  return Get(urls.POSTAL_CODE.replace(':country', country), {cep});
};

export const postInterest = (params) => {
  return Post(`${urls.INTEREST}`, params);
};

export const getCampaigns = payload => {
  return Get(urls.CAMPAIGNS, payload);
};

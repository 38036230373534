/* eslint-disable no-undef */
import {call, put, all, takeEvery, delay} from 'redux-saga/effects';
import {
  PaymentsActionTypes,
  changeStatusPayment,
  changeReturnPayment,
  changeCampaignHasExpired,
  changeInvalidInterest,
  change429Error,
  toggleDisableInterface,
  sendUserToConfirm,
} from 'store/reducers/payments.reducer';
import {postPaymentsCards, postPayments, postPaymentBoleto, postInterest} from 'utils/webApi';

const REACT_APP_MAX_RETRY = process.env.REACT_APP_MAX_RETRY;

function* paymentsCards({payload}) {
  try {
    yield put(changeStatusPayment(null));
    yield put(change429Error(false));

    const MaxRetry = parseInt(REACT_APP_MAX_RETRY);
    let retry = 0;

    yield put(toggleDisableInterface(true));

    try {
      while (true) {
        try {
          const response = yield call(postPaymentsCards, payload);
          yield put(changeReturnPayment(response));
          yield put(changeStatusPayment(200));
          break;
        } catch (error) {
          if (error?.response?.status !== 429 || retry === MaxRetry) {
            throw error;
          }
        }

        yield delay(1000);
        retry++;
      }
    } finally {
      yield put(toggleDisableInterface(false));
      yield put(sendUserToConfirm(true));
    }

  } catch (error) {
    yield put(sendUserToConfirm(true));
    if (error.response.status === 429) {
      yield put(change429Error(true));
      yield put(changeStatusPayment(error.response.status));
    }
    if (error.response.status === 403) {
      yield put(changeCampaignHasExpired(true));
    } else {
      yield put(changeReturnPayment(error.response.data));
      yield put(changeStatusPayment(error.response.status));
    }
  }
}

function* payments({payload}) {
  try {
    yield put(changeStatusPayment(null));
    yield put(change429Error(false));

    const MaxRetry = parseInt(REACT_APP_MAX_RETRY);
    let retry = 0;

    yield put(toggleDisableInterface(true));

    try {
      while (true) {
        try {
          const response = yield call(postPayments, payload);
          yield put(changeReturnPayment(response));
          yield put(changeStatusPayment(200));
          break;
        } catch (error) {
          if (error?.response?.status !== 429 || retry === MaxRetry) {
            throw error;
          }
        }

        yield delay(1000);
        retry++;
      }
    } finally {
      yield put(toggleDisableInterface(false));
      yield put(sendUserToConfirm(true));
    }
  } catch (error) {
    yield put(sendUserToConfirm(true));
    if (error.response.status === 429) {
      yield put(change429Error(true));
      yield put(changeStatusPayment(error.response.status));
    }
    if (error.response.status === 403) {
      yield put(changeCampaignHasExpired(true));
    } else {
      yield put(changeReturnPayment(error.response.data));
      yield put(changeStatusPayment(error.response.status));
    }
  }
}

function* paymentBoleto({payload}) {
  try {
    yield put(changeStatusPayment(null));
    yield put(change429Error(false));

    const MaxRetry = parseInt(REACT_APP_MAX_RETRY);
    let retry = 0;

    yield put(toggleDisableInterface(true));

    try {
      while (true) {
        try {
          const response = yield call(postPaymentBoleto, payload);
          yield put(changeReturnPayment(response));
          yield put(changeStatusPayment(200));

          break;
        } catch (error) {
          if (error?.response?.status !== 429 || retry === MaxRetry) {
            throw error;
          }
        }

        yield delay(1000);
        retry++;
      }
    } finally {
      yield put(toggleDisableInterface(false));
      yield put(sendUserToConfirm(true));
    }
  } catch (error) {
    yield put(sendUserToConfirm(true));
    if (error.response.status === 429) {
      yield put(change429Error(true));
      yield put(changeStatusPayment(error.response.status));
    }
    if (error.response.status === 403) {
      yield put(changeCampaignHasExpired(true));
    } else {
      yield put(changeReturnPayment(error.response.data));
      yield put(changeStatusPayment(error.response.status));
    }
  }
}

function* interest({payload}) {
  try {
    yield call(postInterest, payload);
  } catch (error) {
    yield put(changeInvalidInterest(true));
  }
}

export default function* MySaga() {
  yield all([
    yield takeEvery(PaymentsActionTypes.ASYNC_PAYMENTS_CARDS, paymentsCards),
    yield takeEvery(PaymentsActionTypes.ASYNC_PAYMENTS, payments),
    yield takeEvery(PaymentsActionTypes.ASYNC_PAYMENT_BOLETO, paymentBoleto),
    yield takeEvery(PaymentsActionTypes.ASYNC_INTEREST, interest),
  ]);
}

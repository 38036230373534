import {routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';

import RootReducer from './reducers/reducers';
import RootSaga from './sagas/sagas';

const sagaMiddleware = createSagaMiddleware();
const history = createBrowserHistory();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const Store = createStore(
  RootReducer(history),
  composeEnhancer(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
    ),
  ),
);

sagaMiddleware.run(RootSaga);
export default Store;

export {history};

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
`;

/* global process */
export const urlApi = process.env.REACT_APP_API_URL;
export const urlCountries = process.env.REACT_APP_API_COUNTRIES;

export const urls = {
  PRODUCT: `${urlApi}/v1/checkout/products/:code`,
  SESSION: `${urlApi}/v1/checkout/products/session/:id`,
  LEADS: `${urlApi}/v1/checkout/leads`,
  PAYMENTS: `${urlApi}/v1/checkout/payments`,
  PAYMENTS_CARDS: `${urlApi}/v1/checkout/payments/credits`,
  PAYMENT_BOLETO: `${urlApi}/v1/checkout/payments/boleto`,
  STATES: `${urlCountries}/v1/countries/:country/states`,
  POSTAL_CODE: `${urlCountries}/v1/countries/:country/cep/verify`,
  INTEREST: `${urlApi}/v1/checkout/interest`,
  CAMPAIGNS: `${urlApi}/v1/checkout/products/options`,
  PAYMENT_PIX: `${urlApi}/v1/checkout/payments/pix`,
  PAYMENT_PIX_STATUS: `${urlApi}/v1/checkout/payments/pix/status`,
};

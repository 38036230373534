import styled, {css} from 'styled-components';

export const Container = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TitleInput = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;

  p {
    font-family: 'Geomanist Black';
    letter-spacing: 0.14px;
    color: #c6a106;
  }

  small {
    margin-left: 6px;
    font-size: 10px;
    letter-spacing: 0.11px;
    color: #927602;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 10px;

  background: #fff;
  border-radius: 6px;
  border: 1px solid #414141;
  color: #c6a007;
  & + div {
    margin-top: 8px;
  }
  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}
  input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;

    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.18px;
    color: #414141;
    &::placeholder {
      color: #666360;
    }
  }
  svg {
    margin-right: 16px;
  }
`;

export const Error = styled.div`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }
`;

export const ErrorCaption = styled.p`
  margin: 5px;

  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  text-align: center;
  color: #b80101;
`;

import timeIsOver from 'assets/images/time-is-over.svg';
import {Button, Icon} from 'components';
import React from 'react';

import {Container, Content} from './styles';

function Modal({onClick, subtitle}) {
  return (
    <Container>
      <section>
        <header>
          <span>ATENÇÃO!</span>
          <button onClick={onClick}>
            <Icon className='teste' name='icon-close' width='18px' height='18px' />
          </button>
        </header>
        <Content>
          <img src={timeIsOver} alt='' />
          <span>{subtitle}</span>
          <Button onClick={onClick}>TENTE NOVAMENTE</Button>
        </Content>
      </section>
    </Container>
  );
}

export default Modal;

import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  p, small {
    margin-left: 8px;

    font-size: 18px;
    text-align: center;
    color: #cea912;
  }

  p {
    font-weight: bold;
  }

  small {
    font-weight: normal;
  }
`;

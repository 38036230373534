import React from 'react';

import ProductProvider from './ProductProvider';

const AppProvider = ({children}) => (
  <ProductProvider>
    {children}
  </ProductProvider>
);

export default AppProvider;

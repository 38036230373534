import {Icon} from 'components';
import React, {useEffect, useState, useMemo} from 'react';

import {Container} from './styles';

function CountdownTimer({onChange = () => { }, counter}) {
  const [timeIsOver, setTimeIsOver] = useState(false);

  const timer = useMemo(() => {
    if (counter > 0) {
      const hours = Math.floor(counter / 3600) > 9 ? Math.floor(counter / 3600) : `0${Math.floor(counter / 3600)}`;
      const minutes = Math.floor((counter % 3600) / 60) > 9 ? Math.floor((counter % 3600) / 60) : `0${Math.floor((counter % 3600) / 60)}`;
      const seconds = counter % 60 > 9 ? counter % 60 : `0${counter % 60}`;

      return `${hours}:${minutes}:${seconds}`;
    } else {
      return '00:00:00';
    }
  }, [counter]);

  useEffect(() => {
    if (counter <= 0) {
      setTimeIsOver(true);
    }
  }, [counter]);

  useEffect(() => {
    onChange(timeIsOver);
  }, [timeIsOver, onChange]);

  return (
    <>
      <Container>
        <Icon name='icon-timer' width='36' height='26' />
        <p>{timer}</p>
        <small>fim da oferta</small>
      </Container>
    </>
  );
}

export default CountdownTimer;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Check = styled.div`
  width: 20px;
  height: 20px;
  background: #fdfdfd;
  border: 1px solid #c6a007;

  cursor: pointer;
  position: relative;

  &.checked {
    background: #c6a007;

    &::before {
      position: absolute;
      content: '';
      display: block;
      top: 2px;
      left: 6px;
      width: 4px;
      height: 10px;
      border-style: solid;
      border-color: #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 1;
    }
  }

  transition: background .2s ease;
`;

export const Label = styled.span`
  margin-left: 10px;
  margin-top: 2px;

  color: #414141;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;

  text-align: left;

  > a {
    text-decoration: none;
    color: #c6a007;

    &:hover {
      opacity: .8;
      cursor: pointer;
    }
  }
`;

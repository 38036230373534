/* eslint-disable no-undef */
//Type

export const PaymentsActionTypes = {
  ASYNC_PAYMENTS_CARDS: '@payments/ASYNC_PAYMENTS_CARDS',
  ASYNC_PAYMENTS: '@payments/ASYNC_PAYMENTS',
  ASYNC_PAYMENT_BOLETO: '@payments/ASYNC_PAYMENT_BOLETO',
  ASYNC_INTEREST: '@payments/ASYNC_INTEREST',
  CHANGE_STATUS_PAYMENT: '@payments/CHANGE_STATUS_PAYMENT',
  CHANGE_RETURN_PAYMENT: '@payments/CHANGE_RETURN_PAYMENT',
  CHANGE_CAMPAIGN_HAS_EXPIRED: '@payments/CHANGE_CAMPAIGN_HAS_EXPIRED',
  RESET_FORMIK_FORM: '@payments/RESET_FORMIK_FORM',
  CHANGE_429_ERROR_STATUS: '@payments/CHANGE_429_CHANGE_429_ERROR_STATUS',
  CHANGE_DISABLE_INTERFACE: '@payments/CHANGE_DISABLE_INTERFACE',
  SEND_USER_TO_CONFIRM: '@payments/SEND_USER_TO_CONFIRM',
  INVALID_INTEREST: '@payments/INVALID_INTEREST',
};

const INITIAL_STATE = {
  statusPayment: null,
  returnPayment: null,
  campaign_has_expired: false,
  invalid_interest: false,
  resetForm: 0,
  is429Error: false,
  sendToConfirm: false,
  disableInterface: false,
};

export const paymentsReducer = (state = INITIAL_STATE, {type, payload}) => {
  switch (type) {
  case PaymentsActionTypes.CHANGE_STATUS_PAYMENT:
    return {...state, statusPayment: payload};
  case PaymentsActionTypes.CHANGE_RETURN_PAYMENT:
    return {...state, returnPayment: payload};
  case PaymentsActionTypes.CHANGE_CAMPAIGN_HAS_EXPIRED:
    return {...state, campaign_has_expired: payload};
  case PaymentsActionTypes.RESET_FORMIK_FORM:
    return {...state, resetForm: state.resetForm + 1};
  case PaymentsActionTypes.CHANGE_DISABLE_INTERFACE:
    return {...state, disableInterface: payload};
  case PaymentsActionTypes.CHANGE_429_ERROR_STATUS:
    return {...state, is429Error: payload};
  case PaymentsActionTypes.SEND_USER_TO_CONFIRM:
    return {...state, sendToConfirm: payload};
  case PaymentsActionTypes.INVALID_INTEREST:
    return {...state, invalid_interest: payload};
  default:
    return state;
  }
};

export const postPaymentsCards = (params) => ({
  type: PaymentsActionTypes.ASYNC_PAYMENTS_CARDS,
  payload: params,
});

export const postPayments = (params) => ({
  type: PaymentsActionTypes.ASYNC_PAYMENTS,
  payload: params,
});

export const postPaymentBoleto = (params) => ({
  type: PaymentsActionTypes.ASYNC_PAYMENT_BOLETO,
  payload: params,
});

export const postPaymentPIX = (params) => ({
  type: PaymentsActionTypes.ASYNC_PAYMENT_BOLETO,
  payload: params,
});

export const postInterest = (params) => ({
  type: PaymentsActionTypes.ASYNC_INTEREST,
  payload: params,
});

export const changeStatusPayment = (code) => ({
  type: PaymentsActionTypes.CHANGE_STATUS_PAYMENT,
  payload: code,
});

export const changeReturnPayment = (payload) => ({
  type: PaymentsActionTypes.CHANGE_RETURN_PAYMENT,
  payload,
});

export const changeCampaignHasExpired = (value) => ({
  type: PaymentsActionTypes.CHANGE_CAMPAIGN_HAS_EXPIRED,
  payload: value,
});

export const changeInvalidInterest = (value) => ({
  type: PaymentsActionTypes.INVALID_INTEREST,
  payload: value,
});

export const resetForm = () => ({
  type: PaymentsActionTypes.RESET_FORMIK_FORM,
});

export const change429Error = (payload) => ({
  type: PaymentsActionTypes.CHANGE_429_ERROR_STATUS,
  payload,
});

export const toggleDisableInterface = (payload) => ({
  type: PaymentsActionTypes.CHANGE_DISABLE_INTERFACE,
  payload,
});

export const sendUserToConfirm = (payload) => ({
  type: PaymentsActionTypes.SEND_USER_TO_CONFIRM,
  payload,
});

export default paymentsReducer;

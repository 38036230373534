import React from 'react';

import IconLoading from './loading.svg';
import {Container} from './styles';

const Button = ({outlined = false, loading = false, disabled = false, children, ...rest}) => (
  <Container
    outlined={outlined}
    disabled={disabled || loading}
    type='button'
    {...rest}
  >
    {loading ? <IconLoading fill='#fff' /> : children}
  </Container>
);

export default Button;
